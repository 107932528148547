html[data-theme="dark"] {
  --bg: #333333;
  --bg-panel: #434343;
  --color-heading: #0077ff;
  --color-text: #b5b5b5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.panel-default {
  position: absolute;
  top: 1rem !important;
  right: 1rem !important;
  max-height: 50% !important;
  width: 20% !important;
  min-width: 300px !important;
  overflow: auto !important;
  background: #181b1f !important;
  font-family: sans-serif;
  border: 1px solid #ccccdc12 !important;
  color: #ccccdc;
}
.panel-loading {
  position: absolute;
  top: 45% !important;
  right: 35% !important;
  max-height: 50% !important;
  width: 20% !important;
  min-width: 300px !important;
  background: transparent !important;
}

.panel-body {
  height: auto;
  font-size: 1.1em;
}

.panel-body.hidden {
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  height: 0;
}

.panel-heading {
  font-size: 1.2em;
}

.panel-default>.panel-heading {
  background-color: rgb(40, 42, 53) !important;
  border-color: rgb(40, 42, 53) !important;
  color: #ccccdc;
}

.panel-heading {
  background-color: rgb(40, 42, 53) !important;
  border-color: rgb(40, 42, 53) !important;
  color: #ccccdc;
}

.dark-form input[type="text"],
.dark-form input[type="email"],
.dark-form input[type="password"],
.dark-form input[type="date"],
.dark-form textarea {
    width: 100%;
    padding: 4px;
    margin: 10px 0;
    background-color: #333;
    border: 1px solid #555;
    color: #ffffff;
    border-radius: 4px;
}

.dark-form input[type="submit"],
.dark-form button {
    background-color: rgb(234, 117, 0);
    color: #ffffff;
    padding: 5px 7px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.dark-form select {
  width: 100%;
    padding: 4px;
    margin: 10px 0px 10px 5px;
    background-color: #333;
    border: 1px solid #555;
    color: #ffffff;
    border-radius: 4px;
}
.dark-form input[type="submit"]:hover,
.dark-form button:hover {
    background-color: #3700b3;
}

.dark-form label {
    display: block;
    margin-bottom: 5px;
}


.whiteline {
  width: 26px;
  height: 2px;
  background-color: #ccccdcd3;
  margin: 5px 0;
}

.tacbox {
  margin-bottom: 7px;
}

.terms-container {
  padding: 20px;
}